(function ($) {


$('.menu-top .parent').hover(function (e){
    var _containerСhilds = $(this).find('.container-childs'),
        heightContainerChilds = 0;
    _containerСhilds.find('li').each(function (){
        heightContainerChilds += +$(this).css('height').slice(0,-2);
    });

    _containerСhilds.css('height', heightContainerChilds +"px");
});
$('.menu-top .parent').mouseleave(function (e){
    $(this).find('.container-childs').css('height', "0px");
});


    $('.menu-link').on('click',function(){
        $('.menu-container').addClass('active');
        $('body').addClass('menu-active');
    });

    $(document).mouseup(function (e){ // событие клика по веб-документу
        var menu = $(".menu-container.active nav"); // тут указываем ID элемента
        if (!menu.is(e.target) // если клик был не по нашему блоку
            && menu.has(e.target).length === 0) { // и не по его дочерним элементам\
            menu.parents('.menu-container.active').removeClass('active'); // скрываем его
            menu.parents('body').removeClass('menu-active'); // скрываем его
        }
    });
    $('.menu-container .parent').on('click', function(e){
        e.stopPropagation();
        $(this).toggleClass('active');
        $(this).find('.parent.active').each(function(){
            $(this).removeClass('active');
        });
    })
    $('.menu-container.active .btn-close').on('click',function(e){
        e.stopPropagation();
        $(this).parent().removeClass('active');

    });

    $('.partners-block .slides').slick({
        autoPlay: true,
        dots: false,
        arrows:true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1359,
                settings: {
                    slidesToShow: 4,
                }
            },

            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
        prevArrow: '.btn-partners-prev',
        nextArrow: '.btn-partners-next'
    });

    $('.link-file').on('click',function(){
        $(this).parents('.container-input-file').find('input[type="file"]').click();
    });


    $('.tabs .tab').on('click',function(e){
        var tabContentActiveId = $(this).data('tab-content-id');

        $(this).parents('.tabs').find('.tab.active').removeClass('active');
        $(this).parents('.tabs').find('.tab-content.active').removeClass('active');
        $(this).parents('.tabs').find('.tab-content[id="'+tabContentActiveId+'"]').addClass('active');
        $(this).addClass('active');

    });
}(jQuery));